/* HowToPlay.css */

.how-to-play-overlay {
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, 0.8);*/
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above all other content */
  overflow-y: auto; /* Allow scrolling */
}

.how-to-play-container {
  background: white;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.close-button {
  float: right;
  border: none;
  background: none;
  font-size: 30px;
  cursor: pointer;
}

.how-to-play-container h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.how-to-play-container p {
  font-size: 20px;
  line-height: 1.5;
}

.how-to-play-container ul {
  margin: 20px 0;
}

.how-to-play-container li {
  margin-bottom: 10px;
}

.instruction-text {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.letter {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 3px solid #000;
  margin-right: 5px;
  font-weight: bold;
  font-size: 25px;
}

.correct {
  background-color: #6aaa64; /* Green */
  color: white;
}

.present {
  background-color: #c9b458; /* Yellow */
  color: white;
}

.absent {
  background-color: #787c7e; /* Dark gray */
  color: white;
}

.bold {
  font-weight: bold;
}
/* Responsive styling for smaller screens */
@media (max-width: 600px) {
  .how-to-play-container {
    width: 100%; /* ensure full width */
    border-radius: 0;
    overflow-y: scroll; /* allow scrolling within the container */
    padding: 20px; /* adjust padding for mobile */
  }

  .how-to-play-container h1,
  .how-to-play-container p,
  .how-to-play-container li {
    font-size: 16px; /* adjust font size */
  }
}
