.title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wordle-title {
  font-size: 36px;
  font-weight: bold;
  padding-left: 10px;
}

.right-buttons {
  margin-right: 10px;
}

.wordle-score {
  margin-left: auto;
  margin-right: auto;
  font-size: 25px;
  font-weight: bold;
}

.button {
  background-color: #6aaa64;
  color: white;
  border-radius: 4px;
  border: 1px solid black;
}

.wordle-container {
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
  height: 70vh;
  background-color: white;
  font-family: "Arial", sans-serif;
}

.wordle-board {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.wordle-row {
  display: flex;
  gap: 3px;
}

.wordle-tile {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #d3d6da;
  font-size: 2em;
  font-weight: bold;
  background-color: white;
  color: black;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.wordle-tile.correct {
  background-color: #6aaa64;
  border-color: #6aaa64;
  color: white;
}

.wordle-tile.present {
  background-color: #c9b458;
  border-color: #c9b458;
  color: white;
}

.wordle-tile.absent {
  background-color: #787c7e;
  border-color: #787c7e;
  color: white;
}

.wordle-keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
}

.wordle-keyboard-row {
  display: flex;
  justify-content: center;
  margin: 5px 0;
}

.wordle-key {
  background-color: #d3d6da;
  border: none;
  border-radius: 4px;
  margin: 0 3px;
  padding: 20px 15px;
  min-width: 35px;
  font-size: 1em;
  font-weight: bold;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s;
}

.wordle-key:hover {
  background-color: #a3a6aa;
}

.wordle-key.large {
  flex: 1;
  max-width: 95px; /* Adjust size as needed */
  font-size: 0.8em;
}

.backspace {
  width: 35px;
}
/* Style for keys that have been used in a guess */
.wordle-key.correct,
.wordle-key.present,
.wordle-key.absent {
  background-color: #6aaa64;
  color: white;
}

.wordle-key.present {
  background-color: #c9b458;
}

.wordle-key.absent {
  background-color: #787c7e;
}

.win-message {
  text-align: center;
  margin-top: 20px;
}

.play-again-button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}
.invalid-word-message,
.lost-message,
.win-message {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: adds subtle shadow */
  background-color: white;
}

.invalid-word-message,
.correct-word {
  color: white;
  background-color: black;
  padding: 5px;
  border-radius: 5px;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

.wordle-tile.shake {
  animation: shake 1s ease-in-out;
}

@keyframes flipTileVertical {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(90deg);
    /* Here we need to change the background color at 50% */
  }
  100% {
    transform: rotateX(0deg);
  }
}

.wordle-tile.flip {
  animation: flipTileVertical 1.2s ease forwards;
}

/* App.css or a separate CSS file for PlayerNameInput */

.player-name-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 434px;
}

.player-name-input-container input {
  margin: 10px;
  padding: 10px;
  font-size: 16px;
}

.player-name-input-container button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.current-player-indicator {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
}

@media (max-width: 600px) {
  .wordle-title {
    font-size: 18px;
    font-weight: bold;
    display: block;
    padding-left: 10px;
  }

  .wordle-score {
    font-size: 12px;
  }

  .button-icon {
    height: 15px;
    width: 15px;
  }

  .current-player-indicator {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    margin: 10px 0;
  }

  .wordle-tile {
    width: 15vw;
    height: 15vw;
  }

  .wordle-key {
    font-weight: bold;
    font-size: 1.2em;
    padding: 15px 8px;
    min-width: 30px;
  }

  /* Adjust the keyboard layout to ensure it's fully visible */
  .wordle-keyboard {
    flex-wrap: wrap; /* Wrap keys to the next line if they don't fit */
    justify-content: center; /* Center the keys to maintain layout */
  }
}
